<template>
    <div class="mb-5 container">
        <div class="fl-te-c pb-3">
            <h4 v-html="''"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.focusAreaAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn @click="setEdit(rowData)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Focus Area" ref="focusAreaAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddFocusArea @success="formSuccess"></AddFocusArea>
        </modal>

        <modal title="Edit Focus Area" ref="focusAreaEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditFocusArea :initial-data="editingItem" @success="formSuccess"></EditFocusArea>
        </modal>

        <delete-modal ref="focusAreaDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Focus Area <b v-html="deletingItem && deletingItem.area"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../../data/urls';
import AddFocusArea from '@/views/admin/programs/focus-area/AddFocusArea';
import EditFocusArea from '@/views/admin/programs/focus-area/EditFocusArea';

export default {
    name : 'FocusArea',

    components : { AddFocusArea, EditFocusArea },

    data () {
        return {
            listUrl      : urls.programs.focusArea.list + '?id=' + this.$route.params.id,
            deleteUrl    : urls.programs.focusArea.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'area',
                    sortField  : 'area',
                    title      : 'Area',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },

    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.focusAreaAddModal.close();
            refs.focusAreaEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.focusAreaEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.focusAreaDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Focus Area..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.focusAreaDeleteModal.close();
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
