<template>
    <b-form :save-url="addUrl" @success="formSuccess" :save-params="{program: $route.params.id}" v-slot="{model, loading}">

        <file-input class="col-12" label="Image" name="Img" v-model="model.img" :disabled="loading"/>

        <btn class="trn" size="sm" icon="fa fa-save" :loading="loading" color="primary" text="Save"
                                 loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../../data/urls';

export default {
    name : 'AddBanner',

    data () {
        return {
            addUrl : urls.programs.banner.addEdit
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Banner..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
