<template>
    <tabs class="container card">
        <tab title="FOCUS AREA" icon="fa fa-map-marker">
            <focus-area />
        </tab>
        <tab title="BANNER" icon="fa fa-picture-o">
            <banner />
        </tab>
    </tabs>
</template>

<script>
import FocusArea from '@/views/admin/programs/focus-area/FocusArea';
import Banner from '@/views/admin/programs/banner/Banner';

export default {
    name : 'Details',

    components : { FocusArea, Banner }
};
</script>

<style scoped>

</style>
