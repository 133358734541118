<template>
    <div class="mb-5 container">
        <div class="fl-te-c pb-3">
            <h4 v-html="''"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.bannerAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #img="{rowData}">
                <img :src="rowData.img" alt="Banner Image" style="width: 200px">
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn @click="setEdit(rowData)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Banner" ref="bannerAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddBanner @success="formSuccess"></AddBanner>
        </modal>

        <modal title="Edit Banner" ref="bannerEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditBanner :initial-data="editingItem" @success="formSuccess"></EditBanner>
        </modal>

        <delete-modal ref="bannerDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Banner <b v-html="deletingItem && deletingItem.id"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../../data/urls';
import AddBanner from '@/views/admin/programs/banner/AddBanner';
import EditBanner from '@/views/admin/programs/banner/EditBanner';

export default {
    name : 'Banner',

    components : { AddBanner, EditBanner },

    data () {
        return {
            listUrl      : urls.programs.banner.list + '?id=' + this.$route.params.id,
            deleteUrl    : urls.programs.banner.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : '__slot:img',
                    sortField  : 'img',
                    title      : 'Img',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },

    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.bannerAddModal.close();
            refs.bannerEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.bannerEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.bannerDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Banner..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.bannerDeleteModal.close();
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
