<template>
    <b-form :save-url="addUrl" @success="formSuccess" :save-params="{program: $route.params.id}" v-slot="{model, loading}">

        <validated-input class="col-12" label="Area" name="Area" v-model="model.area"
                    :rules="rules.area" :disabled="loading"/>

        <btn class="trn" size="sm" icon="fa fa-save" :loading="loading" color="primary" text="Save"
                                 loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../../data/urls';

export default {
    name : 'AddFocusArea',

    data () {
        return {
            addUrl : urls.programs.focusArea.addEdit,
            rules  : {
                area : {
                    required : true
                }
            }
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Focus Area..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
